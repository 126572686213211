import React from "react"
import PersonalLoan from "../../components/body/pages/personal-loan"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const PersonalLoanPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/personal-loan"}
      title="Personal Loans | Fast Payment After Approval | Kuda"
      description="Get a Kuda loan of up to ₦150,000 in minutes on the Kuda app, repay in monthly installments. Apply today!"
    />
    <PersonalLoan />
  </Layout>
)

export default PersonalLoanPage
